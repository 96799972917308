import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import cx from "classnames"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Indentation from "../components/indentation"
import WorkItem from "../components/work-item"
import Contact from "../components/contact"
import "./index.scss"

const IndexPage = () => {
  const [isContactActive, setIsContactActive] = useState(false)
  function toggleContact() {
    document.body.style.overflow = isContactActive ? "visible" : "hidden"
    setIsContactActive(!isContactActive)
  }
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: {
          fields: [frontmatter___year, fields___dateCreated]
          order: [DESC, DESC]
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            html
            frontmatter {
              title
              tools
              year
            }
          }
        }
      }
    }
  `)

  return (
    <div className={cx("home", { locked: isContactActive })}>
      <Layout toggleContact={toggleContact}>
        <SEO title="Home" />
        <section id="intro" className="intro section">
          <h1 className="intro__title">
            front-end engineer
            <span className="intro__title--light"> currently</span>
            <br />
            <span className="intro__title--light">in </span>denver,
            <br />
            co
          </h1>
        </section>

        <section id="work" className="section">
          <h1 className="section__title">work</h1>
          <Indentation>
            {data.allMarkdownRemark.edges.map(e => (
              <WorkItem key={e.node.fields.slug} node={e.node} />
            ))}
          </Indentation>
        </section>

        <section id="skills" className="section">
          <h1 className="section__title">skills</h1>
          <Indentation>
            <h2>
              javascript, typescript, react, redux, gatsby, graphql, sass,
              rollup, html, node, package development
            </h2>

            <div className="section__detail">
              <h3 className="section__detail__label">Currently Learning</h3>
              <h4 className="section__detail__value">AWS ECS and EC2</h4>
            </div>

            <div className="section__detail">
              <h3 className="section__detail__label">Want to Learn</h3>
              <h4 className="section__detail__value">
                WebGL, AWS, Functional Programming with JavaScript
              </h4>
            </div>
          </Indentation>
        </section>

        <section id="about" className="section">
          <h1 className="section__title">about</h1>
          <Indentation>
            <h2>
              8 years of experience developing and shipping web and mobile
              products.
              <br></br>
              <br></br>
              Currently in Denver, CO, and working remotely for 3 years.
            </h2>

            <div className="hobbies section__detail">
              <h3 className="section__detail__label">Hobbies</h3>
              <h4 className="section__detail__value">
                <a
                  className="hobbies__link"
                  href="https://www.instagram.com/chaddanna/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Home cooking
                </a>
                ,{" "}
                <a
                  className="hobbies__link"
                  href="https://www.instagram.com/hypescript/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  building mechanical keyboards
                </a>
                ,{" "}
                <a
                  className="hobbies__link"
                  href="https://hypescript.itch.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  creating music on an op-1, game jam development & design
                </a>
                , and whiskey tasting
              </h4>
            </div>

            <div className="section__detail">
              <h3 className="section__detail__label">Work Experience</h3>
              <ul className="section__detail__list">
                <li className="section__detail__list__item">
                  Senior Front-end Developer @ iFit [2017 - current]
                </li>
                <li className="section__detail__list__item">
                  Founder / Front-end Engineer @ Formulated [2011 - current]
                </li>
                <li className="section__detail__list__item">
                  Front-end Web Developer @ The Moran Group [2012 - 2016]
                </li>
              </ul>
            </div>

            <div className="section__detail">
              <h3 className="section__detail__label">Education</h3>
              <h4 className="section__detail__value">
                Bachelor of Arts, Graphic Design [2012]
                <br />
                Southeastern Louisiana University
              </h4>
            </div>
          </Indentation>
        </section>
      </Layout>

      <Contact isActive={isContactActive} toggleContact={toggleContact} />
    </div>
  )
}

export default IndexPage
