import React from "react"
import PropTypes from "prop-types"
import "./index.scss"

const Indentation = ({ children }) => (
  <div className="indentation">{children}</div>
)

Indentation.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Indentation
