import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "./index.scss"

const WorkItem = ({ node: { fields, frontmatter } }) => (
  <div className="work-item">
    <Link to={`/projects/${fields.slug}`} key={fields.slug}>
      <h2 className="work-item__title">
        {frontmatter.title} <span className="work-item__arrow">→</span>
      </h2>
      <h3 className="work-item__meta">
        {frontmatter.year} - {frontmatter.tools}
      </h3>
    </Link>
  </div>
)

WorkItem.propTypes = {
  node: PropTypes.shape({
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
    frontmatter: PropTypes.shape({
      tools: PropTypes.string.isRequired,
      year: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default WorkItem
