import React from "react"
import cx from "classnames"
import Indentation from "../indentation"
import "./index.scss"

const socialLinks = [
  {
    label: "github",
    link: "https://github.com/chaddanna",
  },
  { label: "twitter", link: "https://twitter.com/chaddanna" },
  { label: "instagram", link: "https://instagram.com/chaddanna" },
]

const Contact = ({ isActive, toggleContact }) => (
  <div className={cx("contact", { "contact--active": isActive })}>
    <nav className="nav contact__nav">
      <div className="contact__nav__button__container">
        <button
          onClick={toggleContact}
          className="nav__button--caps contact__nav__button"
        >
          hide<span className="nav__button__arrow"> →</span>
        </button>
      </div>
    </nav>
    <div className="section">
      <Indentation>
        <h2 className="section__title">
          to reach out
          <br />
          <span className="section__title--dark">email me at</span>
          <br />
          <a className="section__link" href="mailto:chad@formulated.io">
            chad@formulated.io <span className="section__link__arrow">→</span>
          </a>
        </h2>
        <div className="section__detail">
          <Indentation>
            <h3 className="section__detail__label">find me on</h3>
            <Indentation>
              {socialLinks.map(sl => (
                <h4 className="section__detail__value" key={sl.label}>
                  <a
                    className="section__link--light"
                    href={sl.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {sl.label}
                  </a>
                </h4>
              ))}
            </Indentation>
          </Indentation>
        </div>
      </Indentation>
    </div>
  </div>
)

export default Contact
